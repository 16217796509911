<div class="card rounded-3 mb-4">
  <div class="card-body p-4">
    <div class="row">
      <div class="col-12 fw-bold">
        <h4 i18n>Summary:</h4>
      </div>
    </div>
    <div
      *ngIf="(orderSummary | async)!.order !== null"
      class="row align-items-center"
    >
      <ul class="list-group list-group-flush">
        <ng-container *ngIf="!groupItems">
          <li
            class="list-group-item justify-content-around d-flex align-items-center text-center"
            *ngFor="let item of (orderSummary | async)!.items"
          >
            <span class="col-6"> {{ item.name }} ({{ item.count }}x) </span>
            <span class="col-6"> {{ item.price }}€ </span>
          </li>
        </ng-container>

        <li
          class="list-group-item justify-content-around d-flex align-items-center text-center"
          *ngIf="groupItems"
        >
          <span class="col-6" i18n> Total Goods </span>
          <span class="col-6"> {{ goodsValue | async }}€ </span>
        </li>

        <li
          class="list-group-item justify-content-around d-flex align-items-center text-center"
        >
          <span class="col-6 fw-light" i18n> Shipping Fee </span>
          <span class="col-6">
            {{ (orderSummary | async)!.shippingFee }}€
          </span>
        </li>
        <li
          class="list-group-item justify-content-around d-flex align-items-center text-center"
        >
          <span class="col-6 fw-light" i18n>Payment Fee</span>
          <span class="col-6"> {{ (orderSummary | async)!.paymentFee }}€ </span>
        </li>
        <li
          class="list-group-item justify-content-around d-flex align-items-center text-center fw-bold"
        >
          <span class="col-6" i18n>Total</span>
          <span class="col-6"> {{ (orderSummary | async)!.total }}€ </span>
        </li>
      </ul>
    </div>
  </div>
</div>
