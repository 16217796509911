<section class="h-100">
  <div class="row h-100 py-5 py-0-xs py-0-sm py-3-md">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-10">
        <!-- header -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <h3 class="fw-normal mb-0 text-black" i18n>Create Order</h3>
        </div>

        <!-- summary -->
        <app-order-summary [orderSummary]="orderSummary"></app-order-summary>

        <!-- billing -->
        <div class="card rounded-3 mb-4">
          <div class="card-body p-4">
            <div class="row">
              <div class="col-12 fw-bold">
                <h4 i18n>Billing Address:</h4>
              </div>
            </div>

            <div class="row pt-3 align-content-stretch">
              <app-address-card
                *ngFor="let address of addresses | async"
                class="d-flex col-sm-12 col-md-6 col-lg-4 align-self-stretch"
                [address]="address"
                [selected]="(selectedBillingAddress | async)?.id === address.id"
                (select)="selectBillingAddress($event)"
              ></app-address-card>
            </div>

            <div class="row justify-content-center">
              <button
                type="button"
                (click)="openAddAddressModal()"
                class="btn btn-secondary col-sm-6 col-md-4 col-lg-3"
                i18n
              >
                Add billing info
              </button>
            </div>
          </div>
        </div>

        <!-- shipping -->
        <div class="card rounded-3 mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-12 fw-bold">
                <h4 i18n>Shippping:</h4>
              </div>
            </div>
            <div class="flex-row d-flex justify-content-between">
              <div ngbAccordion [closeOthers]="true" class="col-12">
                <app-delivery-provider-accordion-item
                  *ngFor="
                    let deliveryProvider of deliveryProviders | async | keyvalue
                  "
                  [deliveryProvider]="deliveryProvider.value"
                  [selectedProvider]="
                    (order | async)!.delivery?.provider ?? undefined
                  "
                ></app-delivery-provider-accordion-item>
              </div>
            </div>
          </div>
        </div>

        <!-- Payment -->
        <div class="card rounded-3 mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-12 fw-bold">
                <h4 i18n>Payment:</h4>
              </div>
            </div>
            <div class="flex-row d-flex justify-content-between">
              <div ngbAccordion [closeOthers]="true" class="col-12">
                <app-payment-provider-accordion-item
                  *ngFor="
                    let paymentProvider of paymentProviders | async | keyvalue
                  "
                  [paymentProvider]="paymentProvider.value"
                  [selectedProvider]="
                    (order | async)!.payment?.provider ?? undefined
                  "
                ></app-payment-provider-accordion-item>
              </div>
            </div>
          </div>
        </div>

        <!-- Terms and conditios -->
        <div class="form-check mx-2">
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="termsAndConditionsCheckbox"
            name="termsAndConditionsCheckbox"
            id="tos"
            required
          />
          <label class="form-check-label" for="tos" i18n>
            By clicking here, I state that I have read and understood the
            <a href="https://www.numeroart.com/terms-and-conditions/"
              >terms and conditions</a
            >
          </label>
        </div>

        <!-- Order Button -->
        <div
          class="d-flex justify-content-between align-items-center mb-4 py-3"
        >
          <button
            type="button"
            (click)="placeOrder()"
            class="btn btn-secondary col-sm-6 col-md-4 col-lg-3"
            [disabled]="
              !(isDraftReady | async) || ordering || !termsAndConditionsCheckbox
            "
          >
            {{ ordering ? placingOrderStr : orderStr }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
