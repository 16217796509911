import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';
import { LoginButtonComponent } from './auth/login-button/login-button.component';
import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { Observable, Subscription, switchMap } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { provideHttpClient } from '@angular/common/http';
import { CartIconModalComponent } from './eshop/cart-icon-popover/cart-icon-popover.component';
import {
  NgbActiveModal,
  NgbAlert,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FixedalertWrapperComponent } from './common/fixedalert-wrapper/fixedalert-wrapper.component';
import { NgxCookieConsentModule } from '@localia/ngx-cookie-consent';
import { FrontpageComponent } from './frontpage/frontpage.component';
import { ProfileService } from './auth/profile.service';
import { Store } from '@ngrx/store';
import { appStore } from './app.store';
import { AuthAction } from './auth/store/auth.action';
import { PermissionDirective } from './auth/permission.directive';
import { AnonMigrationComponent } from './common/anon-migration/anon-migration.component';

@Component({
  selector: 'app-root',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterOutlet,
    FontAwesomeModule,
    LoginButtonComponent,
    RouterModule,
    AuthModule,
    CartIconModalComponent,
    NgbCollapseModule,
    NgbDropdownModule,
    FixedalertWrapperComponent,
    NgbAlert,
    NgxCookieConsentModule,
    FrontpageComponent,
    PermissionDirective,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  isAuth0Loading$: Observable<boolean>;
  faSpinner = faSpinner;

  faInstagram = faInstagram;
  faFacebook = faFacebook;

  isMenuCollapsed = true;
  isAdminPanelCollapsed = true;

  @ViewChild('dropdownAdminMenu', { static: false })
  dropdownAdminMenu: ElementRef;

  private modalService = inject(NgbModal);

  contact_url = $localize`https://www.numeroart.com/contact/`;

  private statusChangeSubscription!: Subscription;

  constructor(
    private auth: AuthService,
    private profileService: ProfileService,
    private store: Store<appStore>
  ) {}

  ngOnInit(): void {
    this.isAuth0Loading$ = this.auth.isLoading$;
    this.auth.isAuthenticated$.subscribe((authenticated) => {
      if (authenticated) {
        this.profileService.getPermissions().subscribe((permissions) => {
          this.store.dispatch(AuthAction.permissionsFetched({ permissions }));
        });
        this.profileService.getAnonState().subscribe((anonState) => {
          this.store.dispatch(AuthAction.anonStateFetched({ anonState }));

          if (
            anonState.addressAdded ||
            anonState.cartUsed ||
            anonState.conceptCreated ||
            anonState.orderCreated
          ) {
            this.modalService.open(AnonMigrationComponent, { size: 'xl' });
          }
        });
      } else {
        this.store.dispatch(AuthAction.permissionsFetched({ permissions: [] }));
      }
    });
  }

  ngOnDestroy() {
    this.statusChangeSubscription!.unsubscribe();
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.dropdownAdminMenu.nativeElement.contains(event.target)) {
      this.isAdminPanelCollapsed = true;
    }
  }
}
