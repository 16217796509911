import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ConceptsService } from '../concepts.service';
import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { CommonModule } from '@angular/common';
import {
  Observable,
  Subscription,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { ConceptAction } from '../store/concepts.action';
import { Store } from '@ngrx/store';
import { appStore } from '../../app.store';
import { ImageCropperModule, ImageCroppedEvent } from 'ngx-image-cropper';
import {
  CommonAction,
  getRandomAlertId,
} from '../../common/store/common.action';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConceptFormat, ConceptProfile } from '../store/concept.types';
import { LocalizerPipe } from '../../common/pipes/countryLocalePipe';

@Component({
  selector: 'app-concept-new',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    ImageCropperModule,
    FontAwesomeModule,
    LocalizerPipe
  ],
  templateUrl: './concept-new.component.html',
  styleUrl: './concept-new.component.scss',
})
export class ConceptNewComponent implements OnInit, OnDestroy {
  // Font awesome
  faUpload = faUpload;

  // Hidden file Upload
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  // Upload Form
  createConceptForm = this.fb.group({
    file: ['', Validators.required],
    aspectRatio: ['portrait', Validators.required],
  });

  // Sets to true once cropper loaded, not sure what to use for
  cropperLoaded: boolean = false;

  // Default aspect ration
  aspectRatio: number = 1 / 1.5;

  // Subscription to aspectRatio selections
  aspectRatioChanges$: Subscription;
  urlParamChanges$: Subscription;

  // Event to cropper input
  imageChangedEvent: any = '';

  isUploading: boolean = false;
  conceptCount: Observable<number>;
  canCreateConcept: Observable<boolean>;

  fileLoaded: boolean = false;

  allowed_file_types = ['image/jpeg', 'image/jpg', 'image/png'];

  formats: Observable<ConceptFormat[]>;

  constructor(
    private store: Store<appStore>,
    private fb: FormBuilder,
    private conceptService: ConceptsService,
    private authService: AuthService,
    public auth: AuthService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnDestroy(): void {
    this.aspectRatioChanges$.unsubscribe();
    this.urlParamChanges$.unsubscribe();
  }

  ngOnInit(): void {
    this.formats = this.store.select((state) => state.conceptModule.formats);
    this.formats.pipe(
      filter((pr) => pr.length == 0),
      take(1)
    ).subscribe((profiles) => {
      this.conceptService.listFormats().subscribe((formats) => {
        this.store.dispatch(ConceptAction.listFormats({ formats }))
      })
    })

    this.urlParamChanges$ = this.formats.pipe(switchMap((formats) =>
      this.activatedRoute.queryParams.pipe(
        tap((params) => {
          console.log('got something in', params, formats);
          if (!!params['product']) {
            const ar = formats.find((f) => f.product_slugs.includes(params['product']));
            if (ar) {
              this.aspectRatio = ar.aspectRatio;
              this.createConceptForm.patchValue({ aspectRatio: ar.name })
            }
          }
        })
      )
    )).subscribe()

    //Listen to changes for cropping
    this.aspectRatioChanges$ = this.createConceptForm.controls[
      'aspectRatio'
    ].valueChanges.subscribe((change) => {
      this.formats.pipe(take(1)).subscribe((formats) => {
        this.aspectRatio = formats.find((f) => f.name == change).aspectRatio;
      })
    });

    //Get number of concepts
    this.conceptCount = this.store.select(
      (state) => state.conceptModule.concepts.length
    );

    //Check if user can create concept
    this.canCreateConcept = this.authService.isLoading$.pipe(
      switchMap((loading) => {
        if (loading) {
          return of(false);
        } else {
          return this.authService.isAuthenticated$.pipe(
            mergeMap((authenticated) =>
              this.conceptCount.pipe(
                map((count) => authenticated || count == 0)
              )
            )
          );
        }
      })
    );
  }

  /**
   * Open File Input Selector from bind button
   */
  selectFile() {
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();
  }

  /**
   * Triggered when upload form changes
   * @param event
   */
  onFileChange(event: Event) {
    this.imageChangedEvent = event;
    this.fileLoaded = true;
  }

  /**
   * Triggered if file type is incorrect
   */
  loadImageFailed() {
    this.createConceptForm.controls['file'].setErrors({
      incorrectFileType: true,
    });
    this.fileLoaded = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    if (!this.allowed_file_types.includes(event.blob?.type as string)) {
      this.createConceptForm.controls['file'].setErrors({
        incorrectFileType: true,
      });
      return;
    }

    this.createConceptForm.patchValue({
      file: event.blob as any,
    });
  }

  onSubmit() {
    const file = this.createConceptForm.get('file')?.value as any;
    this.authService.isAuthenticated$.subscribe((auth) => {
      this.isUploading = true;
      this.conceptService
        .createConcept(file, this.createConceptForm.get('aspectRatio')?.value!)
        .pipe(
          tap((concept) => {
            this.isUploading = false;
            this.store.dispatch(ConceptAction.createConcept({ concept }));
          })
        )
        .subscribe({
          next: (res) => {
            this.isUploading = false;
            this.store.dispatch(
              CommonAction.createAlert({
                alert: {
                  id: getRandomAlertId(),
                  type: 'success',
                  message: $localize`Concept created`,
                },
              })
            );
          },
          error: (err: HttpErrorResponse) => {
            this.store.dispatch(
              CommonAction.createAlert({
                alert: {
                  id: getRandomAlertId(),
                  type: 'danger',
                  message:
                    err.error.message ??
                    $localize`Failed to create concept - Unknown error. Please try again later`,
                },
              })
            );
            this.isUploading = false;
          },
        });
    });
  }
}
