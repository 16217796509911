import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { appStore } from '../../../../app.store';
import { Product } from '../../store/product.types';
import { addCartItemDto } from '../../../cart/store/cart.types';
import { CartService } from '../../../cart/cart.service';
import { tap } from 'rxjs';
import { CartAction } from '../../../cart/store/cart.actions';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CommonAction,
  getRandomAlertId,
} from '../../../../common/store/common.action';

@Component({
  selector: 'app-add-to-cart',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './add-to-cart.component.html',
  styleUrl: './add-to-cart.component.scss',
})
export class AddToCartComponent {
  constructor(
    private store: Store<appStore>,
    private cartService: CartService
  ) {}

  @Input() product: Product | null;
  @Input() disabled: boolean = true;
  @Input() count: number = 1;

  addToCart() {
    const item: addCartItemDto = {
      product: this.product?.id!,
      count: this.count,
      item_options: (this.product as Product).product_options
        .filter((e) => !(e.type == 'enum' && !e.selected))
        .map((e) => {
          return {
            product_option: e.id,
            valueOverride: e.value,
          };
        }),
    };

    this.cartService
      .addCartItem(item)
      .pipe(
        tap((cart) => {
          this.store.dispatch(CartAction.refreshCart({ cart }));
        })
      )
      .subscribe((e) => {
        this.store.dispatch(
          CommonAction.createAlert({
            alert: {
              id: getRandomAlertId(),
              type: 'success',
              html: true,
              message: $localize`Item added to <a class="link-success" href='/cart'>cart</a>`,
            },
          })
        );
      });
  }
}
